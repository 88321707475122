// ** Redux Imports
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

// ** Axios Imports
import axios, { AxiosError } from 'axios';
import { UserX } from 'tabler-icons-react';
import ToastWrapper from '@reusable-components/ToastWrapper';
import { deleteToast, errorToast, successToast } from '@universities/store';
import User, { Role } from '@users/types/User';
import { DashboardRole } from '@src/auth/utils';
import PaginationParams from '@custom-components/types/PaginationParams';
import {
  addFeedback,
  deleteFeedback,
  updateFeedback,
} from '../../goin-community/store';

const initState: User[] = [] as User[];

export const banToast = (title: string) => ToastWrapper(
  'Changes are saved!',
  <div>{title}</div>,
  <UserX size={12} />,
  'warning',
);

export const getData = createAsyncThunk(
  'appusers/getData',
  async (params: PaginationParams) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_ENV}/dashboard/users`,
        { params },
      );
      return {
        users: response.data.users,
        count: response.data.count,
        activeUsers: response.data.activeUsers,
        bannedUsers: response.data.bannedUsers,
        deletedUsers: response.data.deletedUsers,
        params,
      };
    } catch (err) {
      errorToast();
      return Promise.reject(err);
    }
  },
);

export const deleteUsers = async (userId: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_ENV}/dashboard/delete-user/${userId}`,
    );
    deleteToast('User successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const exportUsersData = async (universityId: string) => {
  try {
    await axios
      .get(
        `${process.env.REACT_APP_URL_ENV}/universities/${universityId}/users-csv`,
        { responseType: 'blob' },
      )
      .then((res) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = 'goin_app_users';
        link.href = URL.createObjectURL(
          new Blob([res.data], { type: 'text/csv' }),
        );
        link.click();
      });
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
  return null;
};

export const banUsers = async (userId: string) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL_ENV}/dashboard/ban-user/${userId}`,
    );
    banToast('User successfuly banned!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const updateUserRole = async (
  role: string,
  universityId: string,
  userId: string,
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL_ENV}/dashboard/${universityId}/user-role/${userId}`,
      { role },
    );
    successToast('Changes for user role are successfully saved!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const appusersSlice = createSlice({
  name: 'appusers',
  initialState: {
    count: 0,
    activeUsers: 0,
    bannedUsers: 0,
    deletedUsers: 0,
    users: initState,
    isUsersModuleDisabled: false,
    total: 1,
    params: {},
  },
  reducers: {
    setUserEnvironment(state, action) {
      state.users[action.payload.newUserIndex].connections = action.payload.connections;
      state.users[action.payload.newUserIndex].role = action.payload.role;
      state.users[action.payload.newUserIndex].lastGoinScore = action.payload.lastGoinScore;
      state.users[action.payload.newUserIndex].userFeedbackContent = action.payload.feedback;
      state.users[action.payload.newUserIndex].universityId = action.payload.universityId;
      state.users[action.payload.newUserIndex
      ].connectionsWithChampions = action.payload.connectionsWithChampions;
      state.users[action.payload.newUserIndex
      ].connectionsWithNationalities = action.payload.connectionsWithNationalities;
      state.users[action.payload.newUserIndex
      ].groupsCount = action.payload.groupsCount;
      state.users[action.payload.newUserIndex].lastActiveAt = action.payload.lastActiveAt;
      state.users[action.payload.newUserIndex].activityStatus = action.payload.activityStatus;
    },
    updateRoleState(state, action) {
      const userIndex = state.users.findIndex(
        (u) => u._id === action.payload.userId,
      );
      state.users[userIndex].role = action.payload.role as Role;
    },
    updateEnvironmentRoleState(state, action) {
      const userIndex = state.users.findIndex(
        (u) => u._id === action.payload.userId,
      );
      const e = state.users[userIndex].environments.find(
        (e) => e.university === action.payload.universityId,
      );
      if (e) {
        e.role = action.payload.role as Role;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      const userData = localStorage.getItem('userData');
      const universityId = userData ? JSON.parse(userData).universityId : '';
      const role = userData ? JSON.parse(userData).role : '';
      state.users = action.payload.users.map((u: User) => {
        if (role === DashboardRole.UNIVERSITY_ADMIN && u.environments.length) {
          const userEnvironment = u.environments.find(
            (env) => env.university === universityId,
          );
          if (userEnvironment) {
            return {
              ...u,
              connections: userEnvironment.connections,
              lastGoinScore: userEnvironment.lastGoinScore,
              userFeedbackContent: '',
              universityId: userEnvironment.university,
              role: u.environments[0].role,
              lastActiveAt: userEnvironment.lastActiveAt,
              activitystatus: userEnvironment.activityStatus,
            };
          }
        }
        if (role === DashboardRole.SUPER_ADMIN && u.environments.length) {
          const feedback = u.feedback
            ? u.feedback.find(
              (x) => x.universityId === u.environments[0].university,
            )?.content
            : '';
          const environmentStats = u.environmentStats
            ? u.environmentStats.find(
              (x) => x.universityId === u.environments[0].university,
            )?.stats?.connectionsWithChampions
            : 0;
          const nationalitiesConnections = u.environmentStats
            ? u.environmentStats.find(
              (x) => x.universityId === u.environments[0].university,
            )?.stats?.connectionsWithNationalities
            : 0;
          const groupsCount = u.environmentStats
            ? u.environmentStats.find(
              (x) => x.universityId === u.environments[0].university,
            )?.stats?.groupsCount
            : 0;
          return {
            ...u,
            connections: u.environments[0].connections,
            lastGoinScore: u.environments[0].lastGoinScore,
            role: u.environments[0].role,
            userFeedbackContent: feedback || '',
            universityId: u.environments[0].university,
            connectionsWithChampions: environmentStats || 0,
            connectionsWithNationalities: nationalitiesConnections || 0,
            groupsCount: groupsCount || 0,
            lastActiveAt: u.environments[0].lastActiveAt,
            activityStatus: u.environments[0].activityStatus,
          };
        }
        return u;
      });
      state.params = action.payload.params;
      state.count = action.payload.count;
      state.activeUsers = action.payload.activeUsers;
      state.bannedUsers = action.payload.bannedUsers;
      state.deletedUsers = action.payload.deletedUsers;
    });
    builder.addCase(deleteFeedback.fulfilled, (state, action) => {
      const user = state.users.find(
        (u) => u._id === action.payload.data.userId,
      );
      if (user) {
        const feedbackId = user.feedback?.find(
          (f) => f.universityId === action.payload.data.uniId,
        )?._id;
        if (feedbackId && user.feedback) {
          user.feedback = user.feedback.filter((x) => x._id !== feedbackId);
          user.userFeedbackContent = '';
        }
      }
    });
    builder.addMatcher(
      isAnyOf(addFeedback.fulfilled, updateFeedback.fulfilled),
      (state, action) => {
        const user = state.users.find(
          (u) => u._id === action.payload.data.userId,
        );
        if (user) {
          const feedbackIndex = user.feedback?.findIndex(
            (x) => x._id === action.payload.data._id,
          );
          if (
            user.feedback
            && feedbackIndex !== undefined
            && feedbackIndex >= 0
          ) {
            user.feedback[feedbackIndex] = action.payload.data;
            user.userFeedbackContent = action.payload.data.content;
          }
          if (user.feedback && feedbackIndex === -1) {
            user.feedback.push(action.payload.data);
          }
          user.userFeedbackContent = action.payload.data.content;
        }
      },
    );
  },
});

export default appusersSlice.reducer;
export const {
  setUserEnvironment,
  updateRoleState, updateEnvironmentRoleState,
} = appusersSlice.actions;
